import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Input from '../../component/Elements/Input'

const BankDetailsForm: React.FC = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  return (
    <div className='w-full flex-container'>
      <div className='col-md-6'>
        <Input
          register={register}
          classes="col-md-12"
          label={t('settings.form.bloc-bank-details.bank')}
          type="text"
          name="bankName"
          id="bankName"
        />
        <Input
          register={register}
          classes='col-md-12'
          label={t('settings.form.bloc-bank-details.iban')}
          type="text"
          name="bankIban"
          id="bankIban"
        />
        <Input
          register={register}
          classes='col-md-12'
          label={t('settings.form.bloc-bank-details.swift')}
          type='text'
          name="bankSwift"
          id="bankSwift"
        />
      </div>
    </div>
  )
}

export default BankDetailsForm
