import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SelectCustom from '../../component/Elements/Select'

const CommissionForm = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  const options = [
    { value: 'default', label: t('settings.form.bloc-commission.default') },
    { value: 'altarea', label: t('settings.form.bloc-commission.modeAltarea') },
  ]

  return (
    <>
      <fieldset className='w-full fieldset'>
        <legend className='fieldset-legend'>{t('settings.form.bloc-commission.select-format')}</legend>
        <SelectCustom
          classes='col-md-6'
          id='commissionPdfFormat'
          name='commissionPdfFormat'
          label={t('settings.form.bloc-commission.pdfFormat')}
          options={options}
          register={register}
          noChoiceOption
        />
        <SelectCustom
          classes='col-md-6'
          id='commissionCsvFormat'
          name='commissionCsvFormat'
          label={t('settings.form.bloc-commission.csvFormat')}
          options={options}
          register={register}
          noChoiceOption
        />
      </fieldset>
    </>
  )
}

export default CommissionForm
