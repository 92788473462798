import { useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import { toastError } from '../util/Toast'
import RecurrenceMandateGateway from '../../../gateway/Recurrence/RecurrenceMandateGateway'
import { RecurrenceMandateInterface } from '../../../domain/Recurrence/RecurrenceMandate'

interface Props {
  mandateId?: string
}

const useRetrieveMandate = ({ mandateId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [mandate, setMandate] = useState<RecurrenceMandateInterface | null>(null)

  const fetchMandate = useCallback(async () => {
    if (!mandateId) {
      return
    }

    setIsLoading(true)

    try {
      const gateway = new RecurrenceMandateGateway()
      const response = await gateway.get(mandateId)

      if (response) {
        setMandate({
          ...response,
          start: dayjs(response.start, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          end: dayjs(response.end, 'YYYY-MM-DD').format('DD/MM/YYYY')
        })
      }
    } catch (error) {
      toastError('Failed to fetch mandate data')
    } finally {
      setIsLoading(false)
    }
  }, [mandateId])

  useEffect(() => {
    fetchMandate()
  }, [fetchMandate])

  return { isLoading, mandate }
}

export default useRetrieveMandate
