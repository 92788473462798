import React from 'react';
import Upload from "../../component/Sidebar/Settings/Upload";
import HeaderPage from "../../component/HeaderPage/HeaderPage";
import TabSettings from "../../component/Tab/Settings/TabSettings";
import RoleSettingBlock from "../../component/Form/Settings/RoleSetting/RoleSetting";
import {useAppSelector} from "../../../store/hook";
import {useTranslation} from "react-i18next";

function RoleSetting() {
    const {t} = useTranslation()
    const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

    return (
        <>
            <Upload/>
            <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
                <HeaderPage title={t('settings.title')}/>
                <TabSettings/>
                <RoleSettingBlock />
            </main>
        </>
    )
}

export default RoleSetting;
