import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { toast, ToastOptions } from 'react-toastify'
import {Link} from 'react-router-dom'
import imgAlert from '../../../assets/images/icons/alert.svg'
import imgValidation from '../../../assets/images/icons/validation.svg'
import '../../../assets/styles/components/_modal.scss'
import { ApiErrorInterface } from '../../../domain/Api/ApiTypes'
import { ValidationErrorInterface } from '../../../domain/Error/Error'

interface Props {
  message?: string
  type?: 'success' | 'error' | 'warning'
  specificError?: string
}

const getImage = (type: string) => (type === 'success' ? imgValidation : imgAlert)

const Toast: FunctionComponent<Props> = ({ message, type, specificError }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className='content'>
        {type ? (
          <div className='content__image'>
            <img src={getImage(type)} alt='' />
          </div>
        ) : null}
        <p className={`content__text ${specificError ? '' : 'u-pbb'}`}>
          {message ? message : t('common.modal.validation-message')}
        </p>

        {specificError && <span className='content__specific-text'>{specificError}</span>}
      </div>
    </>
  )
}

export const toastOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  icon: false,
}

export const toastSuccess = (message?: string) => {
  toast.success(<Toast message={message} type='success' />, toastOptions)
}

export const toastSuccessWithLinks = (
  message?: string,
  links?: { label: string; to: string }[] // Chaque lien a un label et une destination
) => {
    toast.success(
      <div>
          <Toast message={message} type="success" />
          {links && links.length > 0 && (
            <ul style={{ marginTop: '1rem', paddingLeft: '1.5rem' }}>
                {links.map(({ label, to }, index) => (
                  <li key={index}>
                      <a href={to}>{label}</a>
                  </li>
                ))}
            </ul>
          )}
      </div>,
      toastOptions
    )
}



export const toastProcess = (message?: string) => {
  return toast.success(<Toast message={message} type='success' />, {
    position: 'top-right',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    icon: false,
    autoClose: false,
    progress: 100,
  })
}

export const handleCloseToast = (toastId: any) => {
  if (toastId) {
    toast.dismiss(toastId)
  }
}

export const toastError = (message?: string | Blob, specificError?: string, altMessage?: string) => {
  if (message instanceof Blob) {
    const fr = new FileReader()
    fr.onload = function () {
      const jsonDecoded = JSON.parse(this.result?.toString() ?? '')
      const jsonError = jsonDecoded.error
        ? jsonDecoded.error
        : jsonDecoded.errors[0]?.messages
          ? jsonDecoded.errors[0]?.messages[0]
          : jsonDecoded.errors[0]?.error
            ? jsonDecoded.errors[0]?.error.toString()
            : null
      toast.error(<Toast message={jsonError ?? altMessage} specificError={specificError} type='error' />, toastOptions)
    }
    fr.readAsText(message)
  } else {
    toast.error(<Toast message={message} specificError={specificError} type='error' />, toastOptions)
  }
}

export const toastErrorOnException = (
  error: ApiErrorInterface,
  setValidationFormErrors: React.Dispatch<React.SetStateAction<ValidationErrorInterface | null>>,
  errorMessage: string,
  specificError?: string
) => {
  if (400 === error?.code && 'string' === typeof error?.messageApi) {
    toastError(error.messageApi)
  } else if (error?.blob instanceof Blob) {
    const fr = new FileReader()
    fr.onload = function () {
      const jsonDecoded = JSON.parse(this.result?.toString() ?? '')
      if ('data-validation' === jsonDecoded?.type) {
        setValidationFormErrors(jsonDecoded)
      } else {
        const jsonError = jsonDecoded.error
          ? jsonDecoded.error
          : jsonDecoded.errors[0]?.messages
            ? jsonDecoded.errors[0]?.messages[0]
            : jsonDecoded.errors[0]?.error
              ? jsonDecoded.errors[0]?.error.toString()
              : null
        toast.error(
          <Toast message={jsonError ?? errorMessage} specificError={specificError} type='error' />,
          toastOptions
        )
      }
    }
    fr.readAsText(error?.blob)
  } else if (error?.data?.errors && 'data-validation' === error?.data?.type) {
    setValidationFormErrors(error?.data)
  } else if (error?.data?.title) {
    toastError(error?.data?.title)
  } else {
    toastError(errorMessage)
  }
}

export const toastWarning = (message?: string) => {
  toast.warning(<Toast message={message} type='error' />, toastOptions)
}
