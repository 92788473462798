import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../store/hook'
import HeaderPage from '../../component/HeaderPage/HeaderPage'
import TabSettings from '../../component/Tab/Settings/TabSettings'
import Upload from '../../component/Sidebar/Settings/Upload'
import UserDatalist from '../../component/Datalist/UserDatalist'
import AddUser from '../../component/Sidebar/Settings/AddUser'
import useFetchSettings from '../../customHook/useFetchSettings'
import Spinner from '../../component/Spinner/Spinner'

const UserProfiles: React.FC = () => {
  const { t } = useTranslation()

  const openMainNavigation = useAppSelector(state => state.event.openMainNavigation)
  const userRules = useAppSelector(state => state.me.me?.rules.user.actions)
  const { isLoading } = useFetchSettings()

  if (isLoading) {
    return <section className='container--spinner'>
      <Spinner size={150} />
    </section>
  }

  return (
    <>
      <AddUser />
      <Upload />
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={t('settings.title')} />
        <TabSettings />
        {userRules?.list && <UserDatalist />}
      </main>
    </>
  )
}

export default UserProfiles
