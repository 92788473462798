import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {v4 as uuidV4} from "uuid";
import dayjs from "dayjs";

import InputDate from "../../../Elements/InputDate";
import {ActionItemInterface, ActionList, ActionListInterface} from "../../../../../../domain/Recurrence/RecurrenceSessionAction";
import {handleCloseToast, toastError, toastProcess, toastSuccess} from "../../../../util/Toast";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import {setRecurrenceSessionActions, setRefreshRecurrenceSession} from "../../../../../store/component/recurrence";
import RecurrenceSessionGateway from "../../../../../../gateway/Recurrence/RecurrenceSessionGateway";
import { RecurrenceSessionInterface } from "../../../../../../domain/Recurrence/RecurrenceSession";

interface Props {
  register: any
  control: any
  getValues: any
  setValue: any
  sessionId: string
  uuidRefresh: string,
  recurrences: RecurrenceSessionInterface | undefined
}

export const ACTIONS = {
  CALCUL: 'calcul',
  CONTROL: 'control',
  VERIFY: 'verify',
  VALIDATE: 'validate',
  EXECUTE: 'execute',
  CANCEL: 'cancel'
}

const Actions: FunctionComponent<Props> = ({
  register,
  control,
  getValues,
  setValue,
  sessionId,
  uuidRefresh,
  recurrences
}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch();
  const recurrenceActions = useAppSelector(state => state.recurrence.actions);
  const [actionArray, setActionArray] = useState<ActionListInterface>()
  const [uuidRefreshAction, setUuidRefreshAction] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);

  useEffect(() => {
    if (!firstRender) {
      new RecurrenceSessionGateway().getActions(sessionId).then(actions => dispatch(setRecurrenceSessionActions(actions)))
    }

    setFirstRender(false);
  }, [uuidRefresh, uuidRefreshAction])

  useEffect(() => {
    if (recurrenceActions) {
      let data = {} as ActionListInterface;

      Object.keys(recurrenceActions).map(key => {
        data = {
          ...data,
          [key as keyof ActionListInterface]: {...recurrenceActions[key as keyof ActionListInterface]}
        }
      });

      setActionArray(data)
    }
  }, [recurrenceActions])

  useEffect(() => {
    if (actionArray) {
      Object.entries(actionArray).map((action: [string, ActionItemInterface]) => {
        setValue(action[0] as keyof ActionListInterface, action[1])
      })
    }
  }, [actionArray])

  const setAction = (action: string) => {
    if ([
      ACTIONS.CALCUL,
      ACTIONS.CONTROL,
      ACTIONS.VERIFY,
      ACTIONS.VALIDATE,
      ACTIONS.EXECUTE,
      ACTIONS.CANCEL
    ].includes(action)) {
      setIsLoading(true)
      let date = getValues()[action as keyof ActionListInterface].date
      const reason = getValues()[action as keyof ActionListInterface].reason

      if (action === ACTIONS.CALCUL) {
        date = dayjs(new Date()).format('DD/MM/YYYY');
      } else if (!date) {
        date = dayjs(new Date()).format('DD/MM/YYYY');
      }

      new RecurrenceSessionGateway().setAction(sessionId, action, date, reason).then((response: ActionList | any) => {
        if (response) {
          dispatch(setRecurrenceSessionActions(response))
          dispatch(setRefreshRecurrenceSession(uuidV4()))
          toastSuccess(t('recurrence.session.notify.update-success'))
          return;
        }
        toastError(t('recurrence.session.notify.update-error'))
      }).catch((e) => {
        toastError(t('recurrence.session.notify.update-error'))
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }

  const disabledAction = ({ action }: { action: [string, any]}) :boolean => {
    return isLoading || !(action[1].enabled);
  }
  const handleClickAction = (action: [string, any]) => {
    setAction(action[0])
  }

  const getActionTitle = (action: [string, any]) => {
    return action[0];
  }

  return (
    <div className="col-md-6">
      <div className="form-bloc form-bloc--action">
        <div className="form-bloc__title">{t('recurrence.form.setting.actions-editions.title-action')}</div>
        <div className="form-bloc__form flex-container">
          {actionArray && Object.entries(actionArray).map((action: [string, any]) => {
            return (
              <div key={uuidV4()} className={`col-md-6 actions-item ${disabledAction({action}) && 'disabled-content'}`}>
                <button type="button"
                        className="button button--white"
                        onClick={() => handleClickAction(action)}
                >{t(`recurrence.form.setting.actions-editions.${getActionTitle(action)}`)}
                </button>
                <InputDate register={register}
                           control={control}
                           type={'text'}
                           name={`${action[0]}.date`}
                           id={`${action[0]}`}
                           disabled={disabledAction({ action })}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Actions
