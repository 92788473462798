import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CheckboxToggle from '../../component/Elements/CheckboxToggle'

const RecurrenceForm = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  return (
    <>
      <CheckboxToggle
        classes='col-md-12'
        id='isEnableRecurrenceModifyPrice'
        name='isEnableRecurrenceModifyPrice'
        register={register}
        label={t('settings.form.bloc-recurrence.isEnableRecurrenceModifyPrice')}
      />
    </>
  )
}

export default RecurrenceForm
