import React, { FunctionComponent, useCallback, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'
import { ApiErrorInterface } from '../../../../../domain/Api/ApiTypes'
import { CommissioningSessionInterface } from '../../../../../domain/Commissioning/CommissioningList'
import { ISettingForm } from '../../../../../domain/Commissioning/setting'
import { ValidationErrorInterface } from '../../../../../domain/Error/Error'
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface'
import CommissioningSessionGateway from '../../../../../gateway/Commissioning/CommissioningSessionGateway'
import { useAppSelector } from '../../../../store/hook'
import download from '../../../util/Download'
import downloadBlobFile from '../../../util/DownloadBlobFile'
import { usePrompt } from '../../../util/Navigation'
import { setReadOnlyFormElements } from '../../../util/setReadOnlyFormElements'
import { toastError, toastErrorOnException, toastSuccess } from '../../../util/Toast'
import FormFieldErrors from '../../Alert/FormFieldErrors/FormFieldErrors'
import Footer from '../../Footer/Footer'
import ConfirmationModal from '../../Modal/ConfirmationModal'
import EditionAndDownload, { TEditionDownloadItem } from '../Blocks/EditionAndDownload'
import SessionCriteriaToggle from '../Blocks/SessionCriteriaToggle/SessionCriteriaToggle'
import SessionSetting from '../Blocks/SessionSetting/SessionSetting'
import ActionsForm from './ActionsForm'
import { TFieldErrors } from './types';

type TProps = {
  commissioning?: CommissioningSessionInterface
  isLectureMode?: boolean
}

const Setting: FunctionComponent<TProps> = ({ commissioning, isLectureMode }) => {
  const { t } = useTranslation()
  const { commissioningType, uuid } = useParams()
  const navigate = useNavigate()
  const referential: ReferentielInterface | null = useAppSelector(({ referential }) => referential.referential)

  const { register, control, handleSubmit, reset, getValues, watch, setValue } = useForm<ISettingForm>({
    defaultValues: {
      session: {
        sessionNb: commissioning?.code || '',
        sessionStatut:
          referential?.commission?.status?.find(status => status.value === commissioning?.status)?.label || '',
        sessionPayment: commissioning?.paymentScheduledAt || '',
      },
      actions: {
        calcul: '',
        cancel: '',
        control: '',
        invalidate: '',
        validate: '',
        verify: '',
      },
      editions: {
        accounting: '',
        invoice: '',
        transfer: '',
        syntheses: '',
      },
      sessionCriteria: {
        products: commissioning?.criterias.products || [],
        partners: commissioning?.criterias.partners || [],
        regularization: commissioning?.regularisationBoolean,
        dateStart: commissioning?.period?.start || '',
        dateEnd: commissioning?.period?.end || '',
        multiCriteria: commissioning?.criteriaType === 'multi' || false,
        criteria_movement: commissioning?.criterias?.transactions?.[0].code || '',
        periodicity: commissioning?.periodicity || '',
      },
    },
  })

  const watchCriteriaMovement: string | null | undefined = watch('sessionCriteria.criteria_movement')
  const watchMultiCriteria: boolean | null = watch('sessionCriteria.multiCriteria')
  const watchSessionPayment: string|null|undefined = watch('session.sessionPayment')
  const watchPeriodicity: string|null|undefined = watch('sessionCriteria.periodicity')
  const watchDateStart: string|null|undefined = watch('sessionCriteria.dateStart')
  const watchDateEnd: string|null|undefined = watch('sessionCriteria.dateEnd')
  const watchProducts: any[]|null|undefined = watch('sessionCriteria.products')
  const [uuidRefresh, setUuidRefresh] = useState<string>('')
  const openDatalistFilterCommissioningSession = useAppSelector(
    state => state.event.openDatalistFilterCommissioningSession
  )
  const [fieldErrors, setFieldErrors] = useState<TFieldErrors>({})
  const [formErrors, setFormErrors] = useState<ValidationErrorInterface | null>(null)

  const editionArray: TEditionDownloadItem[] = [
    {
      type: 'accounting',
      title: 'commissioning.form.setting.actions-editions.accounting',
    },
    {
      type: 'invoice',
      title: 'commissioning.form.setting.actions-editions.invoice',
    },
    {
      type: 'transfer',
      title: 'commissioning.form.setting.actions-editions.transfer',
    },
    {
      type: 'syntheses',
      title: 'commissioning.form.setting.actions-editions.syntheses',
    },
  ]

  const handleClickDownload = (item: TEditionDownloadItem) => {
    const sessionId = uuid
    const type = item.type
    if (type === 'syntheses' && sessionId) {
      new CommissioningSessionGateway()
        .getExport(sessionId, openDatalistFilterCommissioningSession.filters)
        .then(response => {
          if (response) {
            download(t(`settings.extract.${type}`), response)
          }
        })
        .catch((response: ApiErrorInterface) => {
          toastErrorOnException(response, setFormErrors, t('commissioning.notify.export-control-error'))
        })
    } else if (sessionId) {
      new CommissioningSessionGateway()
        .getExtractAsBlob(sessionId, type, openDatalistFilterCommissioningSession.filters)
        .then(response => {
          if (response) {
            if (response) {
              downloadBlobFile(response, t(`settings.extract.${type}`))
            }
          }
        })
        .catch((response: ApiErrorInterface) => {
          toastErrorOnException(response, setFormErrors, t('commissioning.notify.export-control-error'))
        })
    }
  }

  const checkParameters = (): boolean => {
    const errors: TFieldErrors = {};
    let success = true;

    if (watchMultiCriteria) {
      if (!watchSessionPayment) {
        errors['sessionPayment'] = t('common.required.date.full');
        success = false
      }
      if (!watchPeriodicity) {
        errors['periodicity'] = t('common.required.select.periodicity');
        success = false
      }
      if (!watchDateStart) {
        errors['dateStart'] = t('common.required.date.full');
        success = false
      }
      if (!watchDateEnd) {
        errors['dateEnd'] = t('common.required.date.full');
        success = false
      }
      if (!watchProducts?.length) {
        errors['products'] = t('common.required.select.product');
        success = false
      }
    } else {
      if ("" === watchCriteriaMovement) {
        errors['movement'] = t('common.required.enter.movement');
        success = false
      }
    }


    if (!success) {
      setFieldErrors(errors);
      return false;
    }

    setFieldErrors({});
    return true;
  };

  const onSubmit: SubmitHandler<ISettingForm> = data => {
    if (!checkParameters()) return;
    console.log('onSubmit')
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose} />
      },
    })
  }
  const onConfirm = (data: ISettingForm) => {
    const type = commissioningType ? commissioningType.replace('_', '') : ''

    if (uuid) {
      new CommissioningSessionGateway()
        .updateCommissioningSession(data, type, uuid)
        .then(() => {
          setUuidRefresh(uuidV4())
          toastSuccess(t('commissioning.notify.update-success'))
        })
        .catch(e => {
          if (e.data?.errors?.session?.[0]) {
            const errorParsed: any = Object.values(e.data.errors.session[0])
            toastError(t('commissioning.notify.update-error'), errorParsed[0])
          } else if (e.data?.errors?.criteria_movement?.errors?.criteria_movement) {
            toastError(t('commissioning.notify.update-error'), e.data.errors.criteria_movement.errors.criteria_movement)
          } else if (e.data?.errors?.start?.errors?.start) {
            toastError(t('commissioning.notify.update-error'), e.data?.errors?.start?.errors?.start)
          } else if (e.data?.errors?.end?.errors?.end) {
            toastError(t('commissioning.notify.update-error'), e.data?.errors?.end?.errors?.end)
          } else {
            toastError(t('commissioning.notify.update-error'))
          }
        })
    } else {
      new CommissioningSessionGateway()
        .postCommissioningSession(data, type)
        .then(res => {
          toastSuccess(t('commissioning.notify.add-success'))
          navigate(`/${t('url.commissioning.edit-setting')}/${res.type}/${res.id}`)
        })
        .catch(e => {
          if (e.data?.errors?.session?.[0]) {
            const errorParsed: any = Object.values(e.data.errors.session[0])
            toastError(t('commissioning.notify.add-error'), errorParsed[0])
          } else if (e.data?.errors?.criteria_movement?.errors?.criteria_movement) {
            toastError(t('commissioning.notify.add-error'), e.data.errors.criteria_movement.errors.criteria_movement)
          } else if (e.data?.errors?.start?.errors?.start) {
            toastError(t('commissioning.notify.add-error'), e.data?.errors?.start?.errors?.start)
          } else if (e.data?.errors?.end?.errors?.end) {
            toastError(t('commissioning.notify.add-error'), e.data?.errors?.end?.errors?.end)
          } else {
            toastError(t('commissioning.notify.add-error'))
          }
        })
    }
    reset(data)
  }

  const { isDirty } = useFormState({ control })

  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm))

  const measuredRef = useCallback(
    node => {
      if (node !== null && isLectureMode) {
        setReadOnlyFormElements(true, node)
      }
    },
    [isLectureMode]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef} className='flex-container'>
      <SessionSetting register={register}
        control={control}
        classes={"col-md-12"}
        parentPath={"session."}
        title={t('commissioning.form.setting.session.title')}
        fieldErrors={fieldErrors}
      />

      <SessionCriteriaToggle
        register={register}
        control={control}
        watch={watch}
        getValues={getValues}
        setValue={setValue}
        classes={uuid ? 'col-md-6' : 'col-md-12'}
        parentPath={'sessionCriteria.'}
        title={t('commissioning.form.setting.sessionCriteria.title')}
        titleSecondary={t('commissioning.form.setting.sessionCriteria.titleSecondary')}
        fieldErrors={fieldErrors}
      />


      {uuid && (
        <ActionsForm
          register={register}
          control={control}
          getValues={getValues}
          setValue={setValue}
          sessionId={uuid}
          uuidRefresh={uuidRefresh}
          commissioning={commissioning}
          setFormErrors={setFormErrors}
        />
      )}
      <div className='col-md-12'>
        <FormFieldErrors
          errors={formErrors?.errors ?? null}
          title={formErrors?.title ?? t('distribution.form.product.form-error-base-message')}
        />
      </div>

      {uuid && (
        <EditionAndDownload
          title={t('commissioning.form.setting.actions-editions.title-edition')}
          register={register}
          control={control}
          editionArray={editionArray}
          sessionId={uuid}
          parentPath={'editions.'}
          classes={'col-md-12'}
          onClick={handleClickDownload}
        />
      )}

      <Footer isLectureMode={isLectureMode}
              onCancelUrl={t('url.commissioning.dashboard')}
      />
    </form>
  )
}

export default Setting
