import {FunctionComponent} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'
import {useAppSelector} from "../../../../store/hook";

const TabSettings: FunctionComponent = () => {
  const {t} = useTranslation()
  const userRules = useAppSelector((state) => state.me.me?.rules.user)

  return (
    <div className={`tab tab--partner`}>
      <NavLink
        to={`/${t('url.settings.general')}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('settings.tab.general')}
      </NavLink>
      <NavLink
        to={`/${t('url.settings.software-setting')}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('settings.tab.software-setting')}
      </NavLink>
      {userRules?.visible && <NavLink
        to={`/${t('url.settings.user-profiles')}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('settings.tab.user-profiles')}
      </NavLink>}
    <NavLink
        to={`/${t('url.settings.role-setting')}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
    >
        {t('settings.tab.role-setting')}
    </NavLink>
    </div>
  )
}

export default TabSettings
