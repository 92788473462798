import Caller from '../../domain/Api/Caller'
import { ActionList } from '../../domain/Movement/Action/Action'
import { MovementListInterface } from '../../domain/Movement/MovementList'
import TransactionGatewayInterface from '../../domain/Movement/TransactionGatewayInterface'
import { PreSubscription, PreSubscriptionInterface } from '../../domain/PreSubscription/PreSubscription'
import { FilterPreSubscriptionInterface } from '../../domain/PreSubscription/PreSubscriptionList'
import { KpiResponseInterface } from '../../infrastructure/presentation/Type/Kpi'
import envVariable from '../../infrastructure/presentation/util/envVariable'

export default class PreSubscriptionGateway implements TransactionGatewayInterface {
  private prepareData(preSubscription: PreSubscription): Record<string, unknown> {
    return {
      propertyType: preSubscription.propertyType,
      comment: preSubscription.comment,
      note: preSubscription.note,
      possessionMode: preSubscription.possessionMode,
      product: preSubscription.product?.id,
      subscribedAt: preSubscription.subscribedAt,
      horodatage: preSubscription.horodatage,
      minor: preSubscription.minor,
      guardianship: preSubscription.guardianship,
      notFrench: preSubscription.notFrench,
      ppe: preSubscription.ppe,
      ter: preSubscription.ter,
      investment50k: preSubscription.investment50k,
      recurrent: preSubscription.recurrent,
      dividendReinvestment: preSubscription.dividendReinvestment,
      counterpartyResearch: preSubscription.counterpartyResearch,
      dateStartMandat: preSubscription.dateStartMandat,
      dateEndMandat: preSubscription.dateEndMandat,
      executionMandat: preSubscription.executionMandat,
      fundReceptionTimingMandat: preSubscription.fundReceptionTimingMandat,
      duration: preSubscription.duration,
      lifeAnnuity: preSubscription.lifeAnnuity,
      shareCount: preSubscription.shareCount,
      sharePrice: preSubscription.sharePrice,
      commissionStatus: preSubscription.commissionStatus,
      investors: preSubscription.investors?.map(investor => {
        return {
          id: investor.id,
          type: investor.type,
          prospect: investor.prospect ? investor.prospect.id : null,
          dismembermentKey: investor.dismembermentKey,
          amount: investor.amount,
          paymentMode: investor.paymentMode,
          checkDepositAt: investor.checkDepositAt,
          paymentStatus: investor.paymentStatus,
          paymentNature: investor.paymentNature,
          paymentDate: investor.paymentDate,
          partnerType: investor.partnerType,
          documents: investor.documents,
          partner: investor.partner?.id || null,
          bankInformations: investor.bankInformations.map(bankI => {
            return {
              id: bankI.id,
              type: bankI.type,
              rumCode: bankI.rumCode,
              rumStatus: bankI.rumStatus,
              directDebitMandateAccepted: bankI.directDebitMandateAccepted,
              bankInformation: bankI.bankInformation?.id,
            }
          }),
        }
      }),
      recurrence: preSubscription.recurrence
        ? {
            status: preSubscription.recurrence.status,
            periodicity: preSubscription.recurrence.periodicity,
            shareCount: preSubscription.recurrence.shareCount,
            sharePrice: preSubscription.recurrence.sharePrice,
            rumCode: preSubscription.recurrence.rumCode,
            rumStatus: preSubscription.recurrence.rumStatus,
            start: preSubscription.recurrence.period.start,
            end: preSubscription.recurrence.period.end,
          }
        : null,
      netassetDate: preSubscription?.netassetDate,
      totalNetAmount: preSubscription?.totalNetAmount,
      source: preSubscription.source || 'manual',
      updateSharePrice: preSubscription.updateSharePrice,
    }
  }

  getList(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterPreSubscriptionInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: MovementListInterface[] | null; numberOfItems: number } | null> {
    const userFilter = filter?.user?.nom || filter?.user?.prenom || filter?.user?.email
    const creationUserFilter = userFilter ? '&filters[creation_user]=' + userFilter : ''
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${creationUserFilter}${sort ? '&sort=' + sort + '&order=' + order : ''}`,
      {
        product_acronyme: filter?.product.label || '',
        transaction_type: filter?.transactionType || '',
        statut: filter?.statut || filter?.status || '',
        tags: filter?.tags || '',
        property_type: filter?.propertyType || '',
        payment_mode: filter?.paymentMode || '',
        creation_user: filter?.user?.email || '',
        name: filter?.name || '',
        keywords: filter?.keywords || '',
        source: filter?.source || '',
      },
      signal
    )
      .then(data => {
        if (data) {
          return data
        }

        return {
          data: [],
          numberOfItems: 0,
        }
      })
      .catch(response => {
        return {
          data: [],
          numberOfItems: 0,
        }
      })
  }

  update(preSubscription: PreSubscription): Promise<PreSubscriptionInterface> {
    return Caller.executePut(
      `${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions/${preSubscription.id}`,
      this.prepareData(preSubscription)
    ).then(data => {
      if (data.id) {
        return data.id
      }

      return null
    })
  }

  create(preSubscription: PreSubscription): Promise<PreSubscriptionInterface> {
    return Caller.executePost(
      `${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions`,
      this.prepareData(preSubscription)
    ).then(data => {
      if (data.id) {
        return data.id
      }

      return null
    })
  }

  get(uuid: string): Promise<PreSubscriptionInterface> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions/${uuid}`, {}).then(
      data => {
        if (data) {
          return data
        }

        return null
      }
    )
  }

  getActions(uuid: string): Promise<ActionList> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions/${uuid}/actions`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }

  setAction(uuid: string, actionSlug: string, date: string, reason: string | null): Promise<ActionList> {
    return Caller.executePut(
      `${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions/action/${uuid}/${actionSlug}`,
      {
        date: date,
        reason: reason,
      }
    ).then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  setMovementCommissionStatus(uuid: string, commissionStatus: string): Promise<PreSubscriptionInterface> {
    return Caller.executePut(
      `${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions/${uuid}/commission-status`,
      { commissionStatus }
    )
      .then(data => data)
      .catch(() => null)
  }

  getExport(filter: FilterPreSubscriptionInterface | null): Promise<string | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions/extract`, {
      product_acronyme: filter?.product.label || '',
      transaction_type: filter?.transactionType || '',
      status: filter?.status?.map(it => it.value) || '',
      tags: filter?.tags.map(it => it.value) || '',
      property_type: filter?.propertyType.map(it => it.value) || '',
      payment_mode: filter?.paymentMode || '',
      creation_user: filter?.user?.email || '',
      name: filter?.name || '',
      keywords: filter?.keywords || '',
      source: filter?.source || '',
    })
      .then(data => {
        if (data && data.content) {
          return data.content
        }

        return null
      })
      .catch(() => null)
  }

  getExportAsBlob(filter: FilterPreSubscriptionInterface | null): Promise<Blob | null> {
    return Caller.executeGetAsBlob(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions/extract`, {
      product_acronyme: filter?.product.label || '',
      transaction_type: filter?.transactionType || '',
      status: filter?.status?.map(it => it.value) || '',
      tags: filter?.tags.map(it => it.value) || '',
      property_type: filter?.propertyType.map(it => it.value) || '',
      payment_mode: filter?.paymentMode || '',
      creation_user: filter?.user?.email || '',
      name: filter?.name || '',
      keywords: filter?.keywords || '',
      source: filter?.source || '',
    })
      .then(data => {
        if (data instanceof Blob) {
          return data
        }

        return null
      })
      .catch(() => null)
  }

  getKpi(): Promise<KpiResponseInterface[] | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions/kpi`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => null)
  }

  updateAction(uuid: string, actionSlug: string, date: string | null): Promise<ActionList> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/${uuid}/${actionSlug}`, {
      date: date,
    }).then(data => {
      if (data) {
        return data
      }

      return null
    })
  }
}
