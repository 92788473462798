import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useAppSelector } from '../../../store/hook'
import HeaderPage from '../../component/HeaderPage/HeaderPage'
import TabSettings from '../../component/Tab/Settings/TabSettings'
import AddCityForm from '../../component/Sidebar/Referentiel/AddCityForm'
import Upload from '../../component/Sidebar/Settings/Upload'
import { toastError, toastSuccess } from '../../util/Toast'
import InitializeSettings from '../../../../useCase/Settings/Initialization/InitializeSettings'
import UpdateUseCase from '../../../../useCase/Settings/Update/UpdateUseCase'
import GeneralSettingsGateway from '../../../../gateway/Settings/GeneralSettings/GeneralSettingsGateway'
import useFetchSettings from '../../customHook/useFetchSettings'
import CollapsibleSection from '../../component/Collapse/CollapsibleSection'
import GeneralInformationForm from '../../component/Settings/GeneralInformationForm'
import BankDetailsForm from '../../component/Settings/BankDetailsForm'
import expandIcon from '../../../../assets/images/icons/arrow-bottom--dark.svg'
import { City } from '../../../../domain/Referentiel/City/City'
import { Country } from '../../../../domain/Referentiel/Country/Country'
import Spinner from '../../component/Spinner/Spinner'

interface IFormInput {
    companyName: string | null
    registration: string | null
    address1: string | null
    address2: string | null
    address3: string | null
    postcode: string | null
    city: City|null
    country: Country|null
    bankName: string | null
    bankIban: string | null
    bankSwift: string | null
}

const GeneralSettings: React.FC = () => {
    const { t } = useTranslation()
    const countryByDefault = useAppSelector(({ referential }) => referential.countryByDefault)
    const openMainNavigation = useAppSelector(({ event }) => event.openMainNavigation)
    const { settings, isLoading } = useFetchSettings()
    const methods = useForm<IFormInput>()

    useEffect(() => {
        if (settings) {
            methods.setValue('companyName', settings.companyName)
            methods.setValue('registration', settings.registration)
            methods.setValue('address1', settings.address1)
            methods.setValue('address2', settings.address2)
            methods.setValue('address3', settings.address3)
            methods.setValue('postcode', settings.postcode)
            methods.setValue('city', settings.city)
            methods.setValue('country', settings.country)
            methods.setValue('bankName', settings.bankName)
            methods.setValue('bankIban', settings.bankIban)
            methods.setValue('bankSwift', settings.bankSwift)
        }
    }, [settings, methods.setValue, methods])

    const onSubmit = methods.handleSubmit(data => {
        const updatedSettings = settings || new InitializeSettings().initializeSettings()
        Object.assign(updatedSettings, data)

        new UpdateUseCase(new GeneralSettingsGateway())
          .execute(updatedSettings)
          .then(response => {
              if (response) toastSuccess(t('settings.notify.update-success'))
              else toastError(t('settings.notify.update-error'))
          })
    })

    if (isLoading) {
        return <section className='container--spinner'>
            <Spinner size={150} />
        </section>
    }

    return (
      <>
          <AddCityForm />
          <Upload />
          <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
              <HeaderPage title={t('settings.title')} />
              <TabSettings />

              <FormProvider {...methods}>
                  <form onSubmit={onSubmit}>
                      <CollapsibleSection title={t('settings.form.bloc-general-information.title')} icon={expandIcon} isOpenByDefault={true}>
                          <GeneralInformationForm countryByDefault={countryByDefault?.[0] || null} />
                      </CollapsibleSection>

                      <CollapsibleSection title={t('settings.form.bloc-bank-details.title')}  icon={expandIcon}>
                          <BankDetailsForm />
                      </CollapsibleSection>

                      <footer className="form-bloc__footer">
                          <button type="submit" className="button button--ink-2">
                              {t('common.save')}
                          </button>
                          <button type="button" className="button button--ink-2 button--ink-2--outline">
                              {t('common.cancel')}
                          </button>
                      </footer>
                  </form>
              </FormProvider>
          </main>
      </>
    )
}

export default GeneralSettings
