import { useState, useEffect } from 'react'
import { SettingsInterface } from '../../../domain/Settings/GeneralSettings/Settings'
import GeneralSettingsGateway from '../../../gateway/Settings/GeneralSettings/GeneralSettingsGateway'
import GetUseCase from '../../../useCase/Settings/Get/GetUseCase'

const useFetchSettings = () => {
  const [settings, setSettings] = useState<SettingsInterface | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const response = await new GetUseCase(new GeneralSettingsGateway()).execute()
        setSettings(response)
      } catch (err) {
        setError('Failed to fetch settings')
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetch()
  }, [])

  return { settings, isLoading, error }
}

export default useFetchSettings
