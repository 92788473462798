import React, { useState } from 'react'

interface CollapsibleSectionProps {
  title: string
  children: React.ReactNode
  isOpenByDefault?: boolean
  icon?: string
  classes?: string
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  children,
  isOpenByDefault = false,
  icon,
  classes = '',
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault)

  const toggleCollapse = () => {
    setIsOpen(prev => !prev)
  }

  return (
    <div className={`form-bloc ${classes}`}>
      <button
        type='button'
        className={`collapse__title-full form-bloc__title ${isOpen ? 'collapse__title--active' : ''}`}
        onClick={toggleCollapse}
      >
        {title}
        {icon && <img src={icon} alt='' />}
      </button>
      {isOpen && <div className='collapse__content-full form-bloc__form flex-container'>{children}</div>}
    </div>
  )
}

export default CollapsibleSection
