import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'
import { DocumentType } from '../../../../../../domain/Document/DocumentInterface'
import CustomerGateway from '../../../../../../gateway/Customer/CustomerGateway'
import AddUseCase from '../../../../../../useCase/Customer/Add/AddUseCase'
import InitializeCustomer from '../../../../../../useCase/Customer/Initialization/InitializeCustomer'
import UpdateUseCase from '../../../../../../useCase/Customer/Update/UpdateUseCase'
import { useAppSelector } from '../../../../../store/hook'
import { usePrompt } from '../../../../util/Navigation'
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements'
import { toastError, toastSuccess } from '../../../../util/Toast'
import FormErrorInfo from '../../../Alert/FormErrorInfo/FormErrorInfo'
import FooterBlock from '../../../Block/FooterBlock'
import ConfirmationModal from '../../../Modal/ConfirmationModal'
import { GeneralInformationPhysicalPersonProps, IFormInputGeneralInformationPhysicalPerson } from './type'
import { setCustomerValues, setPersonsValues, updateCustomerKYC, updateCustomerPersons } from './_helpers'
import {
  CommunicationBlock,
  DocumentHistoryListBlock,
  DocumentListBlock,
  FiscalityBlock,
  GeneralInformationBlock,
  ImmatriculationBlock,
  KycBlock,
  StateBlock,
} from './_partials'
import DistributionBlock from './_partials/DistributionBlock'

const GeneralInformationPhysicalPerson: FunctionComponent<GeneralInformationPhysicalPersonProps> = ({
  customer,
  isLectureMode,
  handler,
  referential,
  setStatus,
}) => {
  const { t } = useTranslation()
  const { customerType } = useParams()
  const navigate = useNavigate()
  const customerStore = useAppSelector(state => state.customer)

  const [formErrors, setFormErrors] = useState<string[]>([])
  const [documentArray, setDocumentArray] = useState<DocumentType>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const methods = useForm<IFormInputGeneralInformationPhysicalPerson>()
  const { isDirty } = useFormState({ control: methods.control })
  const watchStatus = methods.watch('status')

  useEffect(() => {
    if (customer) {
      setCustomerValues(customer, methods)
      setPersonsValues(customer.persons, methods)
    }
  }, [customer])

  const measuredRef = useCallback(
    node => {
      node && isLectureMode && setReadOnlyFormElements(true, node)
    },
    [isLectureMode]
  )

  const onSubmit: SubmitHandler<IFormInputGeneralInformationPhysicalPerson> = data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose} />
      },
    })
  }
  const onConfirm = async (data: IFormInputGeneralInformationPhysicalPerson) => {
    methods.reset(data)

    let updatedCustomer = customer ?? new InitializeCustomer().initializeCustomer()

    if (!updatedCustomer.customerType) {
      updatedCustomer.customerType = customerType ?? 'other'
    }

    updatedCustomer = {
      ...updatedCustomer,
      partner: customerStore?.partner?.id ? customerStore.partner : updatedCustomer.partner,
    }
    updatedCustomer.persons = updateCustomerPersons(updatedCustomer.persons, data, documentArray, 'invest')
    updatedCustomer = updateCustomerKYC(updatedCustomer, data)
    updatedCustomer.distributionPaymentPeriodicity = data.distributionPaymentPeriodicity

    setIsLoading(true)
    const usecase =
      null !== customer.id ? new UpdateUseCase(new CustomerGateway()) : new AddUseCase(new CustomerGateway())

    usecase
      .execute(updatedCustomer)
      .then(uuid => {
        if (!uuid) {
          const messageErrorKey = customer.id
            ? 'account.notify.update-customer-error'
            : 'account.notify.add-customer-error'
          throw new Error(t(messageErrorKey))
        }

        const messageSuccessKey = customer.id
          ? 'account.notify.update-customer-success'
          : 'account.notify.add-customer-success'
        toastSuccess(t(messageSuccessKey))

        if (customer.id) {
          setStatus(watchStatus ?? '')
        } else {
          navigate(`/${t('url.customer.edit-general-information')}/${uuid}`)
        }
      })
      .catch(error => {
        toastError(error.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  usePrompt(isLectureMode ? false : isDirty, methods.handleSubmit(onConfirm))

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} ref={measuredRef}>
        <div className='flex-container'>
          <div className='col-md-12'>
            {formErrors.length ? (
              <FormErrorInfo errors={formErrors} message={t('account.notify.form-error-customer-base-message')} />
            ) : null}
          </div>
          <div className='col-md-12'>
            <ImmatriculationBlock />
          </div>
          <div className='col-md-12'>
            <GeneralInformationBlock
              customer={customer}
              referential={referential}
              isLectureMode={isLectureMode}
              setFormErrors={setFormErrors}
              handler={handler}
            />
          </div>
          <div className='col-md-12'>
            <KycBlock customer={customer} referential={referential} />
          </div>
          <div className='col-md-12'>
            <FiscalityBlock customer={customer} referential={referential} />
          </div>
          <div className='col-md-12'>
            <DistributionBlock referential={referential} />
          </div>
          <div className='col-md-12'>
            <CommunicationBlock customer={customer} referential={referential} isLectureMode={isLectureMode} />
          </div>
          <div className='col-md-12'>
            <StateBlock referential={referential} setFormErrors={setFormErrors} />
          </div>
          <div className='col-md-12'>
            <div className='flex-container'>
              <DocumentListBlock
                customer={customer}
                isLectureMode={isLectureMode}
                documentArray={documentArray}
                setDocumentArray={setDocumentArray}
              />
            </div>
          </div>
          <DocumentHistoryListBlock customer={customer} />
        </div>
        {!isLectureMode && (
          <FooterBlock disabled={!watchStatus || isLoading} onCancelUrl={t('url.customer.dashboard')} />
        )}
      </form>
    </FormProvider>
  )
}

export default GeneralInformationPhysicalPerson
