import React, { useCallback, useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import expandIcon from '../../../../assets/images/icons/arrow-bottom--dark.svg'
import GeneralSettingsGateway from '../../../../gateway/Settings/GeneralSettings/GeneralSettingsGateway'
import InitializeSettings from '../../../../useCase/Settings/Initialization/InitializeSettings'
import UpdateUseCase from '../../../../useCase/Settings/Update/UpdateUseCase'
import { useAppSelector } from '../../../store/hook'
import CollapsibleSection from '../../component/Collapse/CollapsibleSection'
import HeaderPage from '../../component/HeaderPage/HeaderPage'
import CommissionForm from '../../component/Settings/CommissionForm'
import RecurrenceForm from '../../component/Settings/RecurrenceForm'
import TabSettings from '../../component/Tab/Settings/TabSettings'
import { toastError, toastSuccess } from '../../util/Toast'
import useFetchSettings from '../../customHook/useFetchSettings'
import Spinner from '../../component/Spinner/Spinner'

interface IFormInput {
  isEnableRecurrenceModifyPrice: boolean
  commissionPdfFormat: string | null
  commissionCsvFormat: string | null
}

const SoftwareSettings: React.FC = () => {
  const { t } = useTranslation()
  const openMainNavigation = useAppSelector(state => state.event.openMainNavigation)
  const { settings, isLoading } = useFetchSettings()

  const methods = useForm<IFormInput>()

  useEffect(() => {
    if (settings) {
      methods.setValue('isEnableRecurrenceModifyPrice', settings.isEnableRecurrenceModifyPrice || false)
      methods.setValue('commissionPdfFormat', settings.commissionPdfFormat || 'default')
      methods.setValue('commissionCsvFormat', settings.commissionCsvFormat || 'default')
    }
  }, [settings, methods.setValue])

  const onSubmit: SubmitHandler<IFormInput> = useCallback(
    data => {
      const updatedSettings = settings || new InitializeSettings().initializeSettings()
      Object.assign(updatedSettings, data)

      new UpdateUseCase(new GeneralSettingsGateway()).execute(updatedSettings).then(response => {
        if (response) {
          toastSuccess(t('settings.notify.update-success'))
        } else {
          toastError(t('settings.notify.update-error'))
        }
      })
    },
    [settings, t]
  )

  if (isLoading) {
      return <section className='container--spinner'>
        <Spinner size={150} />
      </section>
  }

  return (
    <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
      <HeaderPage title={t('settings.title')} />
      <TabSettings />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CollapsibleSection title={t('settings.form.bloc-recurrence.title')} icon={expandIcon} isOpenByDefault={true}>
            <RecurrenceForm />
          </CollapsibleSection>
          <CollapsibleSection
            title={t('settings.form.bloc-commission.title')}
            icon={expandIcon}
            isOpenByDefault={false}
          >
            <CommissionForm />
          </CollapsibleSection>
          <footer className='form-bloc__footer'>
            <button type='submit' className='button button--ink-2'>
              {t('common.save')}
            </button>
            <button type='button' className='button button--ink-2 button--ink-2--outline'>
              {t('common.cancel')}
            </button>
          </footer>
        </form>
      </FormProvider>
    </main>
  )
}

export default SoftwareSettings
