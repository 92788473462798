import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EditRecurrenceMandateInterface } from '../../../../../domain/Recurrence/RecurrenceMandate'
import RecurrenceMandateGateway from '../../../../../gateway/Recurrence/RecurrenceMandateGateway'
import { useAppSelector } from '../../../../store/hook'
import useRetrieveMandate from '../../../customHook/useRetrieveMandate'
import { toastError, toastSuccess } from '../../../util/Toast'
import Input from '../../Elements/Input'
import InputDate from '../../Elements/InputDate'
import SelectCustom from '../../Elements/Select'
import Spinner from '../../Spinner/Spinner'

type Props = {
  mandateId: string
  readOnly: boolean
}

const MandateForm = ({mandateId, readOnly}: Props) => {
  const { t } = useTranslation()

  const referential = useAppSelector(state => state.referential.referential)

  const { isLoading, mandate } = useRetrieveMandate({ mandateId })

  const { register, control, handleSubmit, reset, getValues } = useForm()

  useEffect(() => {
    if (mandate) {
      reset({
        reference: mandate.id,
        status: mandate.status,
        start: mandate.start,
        end: mandate.end,
        periodicity: mandate.periodicity,
        shareCount: mandate.recurrenceShare,
        rum: mandate.rum,
        rumStatus: mandate.rumStatus,
        recurrenceMode: mandate.recurrenceMode,
      })
    }
  }, [mandate, reset])

  const onConfirm = async (data: EditRecurrenceMandateInterface) => {
    try {
      const gateway = new RecurrenceMandateGateway()
      await gateway.update(data, mandateId)
      toastSuccess(t('recurrence.mandate.edit.notify.update-success'))
    } catch {
      toastError(t('recurrence.mandate.edit.notify.update-error'))
    }
  }

  const checkRecurrenceDate = (): boolean => {
    const date = getValues('end')
    return dayjs().isAfter(dayjs(date, 'DD/MM/YYYY'))
  }

  if (!referential || isLoading) {
    return (
      <section className='container--spinner'>
        <Spinner size={150} />
      </section>
    )
  }

  return (
    <>
      <div className="">
        <form onSubmit={handleSubmit(onConfirm)} className='form-bloc'>
          <div className='sidebar__content'>
            <div className='title'>
              {readOnly ? t('recurrence.mandate.read.title') : t('recurrence.mandate.edit.title')}
            </div>
            <div className='form-bloc__form flex-container'>
              <SelectCustom
                id='status'
                name='status'
                classes='col-md-12'
                register={register}
                label={t('movement.pre-subscription.form.recurrence.status')}
                options={referential?.recurrence.mandate_status || null}
                readOnly={readOnly || checkRecurrenceDate()}
              />
              <Input
                id='reference'
                name='reference'
                classes='col-md-12'
                register={register}
                label={t('movement.pre-subscription.form.recurrence.num-recurrence')}
                readOnly
              />
              <InputDate
                id='start'
                name='start'
                classes='col-md-12'
                register={register}
                control={control}
                label={t('movement.pre-subscription.form.recurrence.start-of-recurrence')}
                readOnly={readOnly}
              />
              <SelectCustom
                id='periodicity'
                name='periodicity'
                classes='col-md-12'
                register={register}
                label={t('movement.pre-subscription.form.recurrence.periodicity-frequency')}
                options={referential?.wallet_subscription.periodicity || null}
                readOnly={readOnly}
              />
              <InputDate
                id='end'
                name='end'
                classes='col-md-12'
                register={register}
                control={control}
                label={t('movement.pre-subscription.form.recurrence.end-of-recurrence')}
                readOnly={readOnly}
              />
              <Input
                id='shareCount'
                name='shareCount'
                classes='col-md-12'
                register={register}
                label={t('movement.pre-subscription.form.recurrence.number-of-shares')}
                readOnly={readOnly}
              />
              <Input
                id='rum'
                name='rum'
                classes='col-md-12'
                register={register}
                label={t('movement.pre-subscription.form.recurrence.rum-code')}
                readOnly={readOnly}
              />
              <SelectCustom
                id='rumStatus'
                name='rumStatus'
                classes='col-md-12'
                register={register}
                label={t('movement.pre-subscription.form.recurrence.rum-status')}
                options={referential?.wallet_subscription.rum_status || null}
                readOnly={readOnly}
              />
            </div>
            <footer className='sidebar__footer'>
              {!readOnly && (
                <button type='submit' className='button button--ink-2'>
                  {t('common.save')}
                </button>
              )}
            </footer>
          </div>
        </form>
      </div>
    </>
  )
}

export default MandateForm
