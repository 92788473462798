import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hook'
import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import TabRecurrence from '../../../component/Tab/Recurrence/TabRecurrence'
import MandateForm from '../../../component/Form/Recurrence/MandateForm'

export const Detail = () => {
  const { t } = useTranslation()
  const { mandateId, mode } = useParams()

  const openMainNavigation = useAppSelector(state => state.event.openMainNavigation)

  return (
    <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
      <HeaderPage title={t('recurrence.title')} />
      <TabRecurrence />

      {mandateId && mode && <MandateForm mandateId={mandateId} readOnly={mode === 'read'} />}
    </main>
  )
}
