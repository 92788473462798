import store from '../../../infrastructure/store/store'
import GeneralSettingsGateway
  from "../../../gateway/Settings/GeneralSettings/GeneralSettingsGateway";
import { setIsEnableRecurrenceModifyPrice } from '../../../infrastructure/store/component/settings'
import {SettingsInterface} from "../../../domain/Settings/GeneralSettings/Settings";

export default class GetUseCase
{
  public generalSettingsGateway

  constructor(GeneralSettingsGateway: GeneralSettingsGateway) {
    this.generalSettingsGateway = GeneralSettingsGateway
  }

  async execute(): Promise<SettingsInterface|null> {
    return await this.generalSettingsGateway.get().then(response => {
      if (response) {
        store.dispatch(setIsEnableRecurrenceModifyPrice(response.isEnableRecurrenceModifyPrice))
      }
      return response
    })
  }
}
